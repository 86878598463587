import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import caretRight from "../customs_forms/images/caret-right.svg";
import LoggedInNav from "./LoggedInNav";
import InvoiceNumberPopover from "./Popovers/InvoiceNumberPopover";
import CertificateNumberPopover from "./Popovers/CertificateNumberPopover";
import LicenseNumberPopover from "./Popovers/LicenseNumberPopover";
import ReferenceNumberPopover from "./Popovers/ReferenceNumberPopover";

import * as analytics from "../Components/Analytics/Analytics.js";
var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-7a-package-contents-sender-type"
};
class CommercialSender extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);
    this.flowType = this.previousState.flowType;
    this.officialBusiness = this.previousState.officialBusiness;
    const commercialSender = this.previousState.commercialSender;
    if (commercialSender) {
      this.state = {
        ...commercialSender
      };
    } else {
      this.state = {
        isCommercialSender: "",
        referenceNumber: "",
        commercialLicenseNumber: "",
        commercialCertificateNumber: "",
        commercialInvoiceNumber: ""
      };
    }
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.packageContents
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there are no package contents
    window.location.replace("/index");
  };

  handleCommercialSender = (event) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  handleBackButton = () => {
    this.previousState.stepNumber = this.previousState.stepNumber - 1;
    this.props.stepNumberCallback(this.previousState.stepNumber);
    this.previousState.commercialStatus = 0;
    if ((this.flowType === "military" || this.flowType === "militaryToUSA") && this.officialBusiness === "1") {
      this.props.history.push("/non-delivery-option", {
        previousState: this.previousState
      });
    } else {
      this.props.history.push("/package-contents", {
        previousState: this.previousState
      });
    }
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  displayContinueButton = () => {
    return (
      this.state.isCommercialSender &&
      (this.state.isCommercialSender !== "1" ||
        this.state.referenceNumber ||
        this.state.commercialLicenseNumber ||
        this.state.commercialCertificateNumber ||
        this.state.commercialInvoiceNumber)
    );
  };

  componentDidMount = () => {
    this.props.loginCheck();
    if (this.previousState.renderServiceOption && this.previousState.renderServiceOption === 1) {
      this.previousState.itemStatus = 0;
    }
    if (!this.previousState.commercialStatus ||
      (this.previousState.commercialStatus && this.previousState.commercialStatus === 0)) {
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
  };

  handleContinueButton = (e) => {
    e.preventDefault();
    this.previousState.commercialStatus = 1;
    let dataToSend = {
      commercialCertificateNumber: this.state.commercialCertificateNumber,
      commercialInvoiceNumber: this.state.commercialInvoiceNumber,
      commercialLicenseNumber: this.state.commercialLicenseNumber,
      isCommercialSender: this.state.isCommercialSender,
      referenceNumber: this.state.referenceNumber,
      cnsCartId: 0,
      strCnsCartId:
        this.props.location.state.previousState.cartInfo.encodedCartId
    };

    this.props.toggleLoadingBar(true);
    axios
      .post(
        "/CFOApplication/saveCommercialSender",
        JSON.stringify(dataToSend),
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        this.previousState.commercialSender = {
          isCommercialSender: this.state.isCommercialSender,
          referenceNumber:
            this.state.isCommercialSender === "1"
              ? this.state.referenceNumber
              : "",
          commercialLicenseNumber:
            this.state.isCommercialSender === "1"
              ? this.state.commercialLicenseNumber
              : "",
          commercialCertificateNumber:
            this.state.isCommercialSender === "1"
              ? this.state.commercialCertificateNumber
              : "",
          commercialInvoiceNumber:
            this.state.isCommercialSender === "1"
              ? this.state.commercialInvoiceNumber
              : ""
        };
        this.props.history.push("/item-entry", {
          previousState: this.previousState
        });

        dataLayer.element = "Continue";
        analytics.setDataLayerInfo(dataLayer);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  render() {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="row section-row">
                    <div className="col-12">
                      <h2>Are you a commercial sender?</h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-6">
                      <div className="radio-button">
                        <label>
                          <input
                            type="radio"
                            name="isCommercialSender"
                            className="has-dependent"
                            data-dependent="1"
                            value="1"
                            checked={this.state.isCommercialSender === "1"}
                            onChange={this.handleCommercialSender}
                          />
                          <span> Yes</span>
                        </label>
                      </div>
                      <div className="radio-button">
                        <label>
                          <input
                            type="radio"
                            name="isCommercialSender"
                            className="has-dependent"
                            data-dependent="0"
                            value="0"
                            checked={this.state.isCommercialSender === "0"}
                            onChange={this.handleCommercialSender}
                          />
                          <span> No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <p>
                        Select Yes if your package contents require an HS
                        Tariff, certificate, license, or invoice number.
                      </p>
                    </div>
                  </div>
                  {this.state.isCommercialSender === "1" ? (
                    <div className="row section-row" id="row-dependent-1">
                      <div className="col-12">
                        <p>
                          NOTE: The HS Tariff# and country of origin will be
                          entered when adding items to your package.
                        </p>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="referenceNumber">
                                  Reference Number
                                </label>
                                <div className="more-info">
                                  <span
                                    className="more-info-link modal-trigger"
                                    data-toggle="modal"
                                    data-target="#modal-sender-reference"
                                  >
                                    <span>
                                      <ReferenceNumberPopover targetName="returnReferenceNumberPopover" />
                                    </span>
                                  </span>
                                  <div
                                    className="more-info-content modal fade"
                                    id="modal-sender-reference"
                                  >
                                    <div
                                      className="modal-dialog modal-lg"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <p>
                                            Sender's Customs Reference - This
                                            number is for your reference only.
                                            You may enter up to 10 characters.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <input
                                name="referenceNumber"
                                id="referenceNumber"
                                maxLength="10"
                                className="form-control"
                                type="text"
                                placeholder="Up to 10 characters."
                                value={this.state.referenceNumber}
                                onChange={this.handleCommercialSender}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="commercialLicenseNumber">
                                  License Number
                                </label>
                                <div className="more-info">
                                  <span
                                    className="more-info-link modal-trigger"
                                    data-toggle="modal"
                                    data-target="#modal-commercialLicenseNumber"
                                  >
                                    <span>
                                      <LicenseNumberPopover targetName="returnLicenseNumberPopover" />
                                    </span>
                                    {/* <img
                                      className="tooltip-icon"
                                      src={info}
                                      alt="More info"
                                      width="18"
                                      height="18"
                                    /> */}
                                  </span>
                                  <div
                                    className="more-info-content modal fade"
                                    id="modal-commercialLicenseNumber"
                                  >
                                    <div
                                      className="modal-dialog modal-lg"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <p>
                                            If your item is accompanied by a
                                            license or a certificate, enter the
                                            number. You should enclose an
                                            invoice for all commercial items.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <input
                                name="commercialLicenseNumber"
                                id="commercialLicenseNumber"
                                className="form-control"
                                type="text"
                                value={this.state.commercialLicenseNumber}
                                onChange={this.handleCommercialSender}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="commercialCertificateNumber">
                                  Certificate Number
                                </label>
                                <div className="more-info">
                                  <span
                                    className="more-info-link modal-trigger"
                                    data-toggle="modal"
                                    data-target="#modal-commercialCertificateNumber"
                                  >
                                    <span>
                                      <CertificateNumberPopover targetName="returnCertificateNumberPopover" />
                                    </span>
                                    {/* <img
                                      className="tooltip-icon"
                                      src={info}
                                      alt="More info"
                                      width="18"
                                      height="18"
                                    /> */}
                                  </span>
                                  <div
                                    className="more-info-content modal fade"
                                    id="modal-commercialCertificateNumber"
                                  >
                                    <div
                                      className="modal-dialog modal-lg"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <p>
                                            If your item is accompanied by a
                                            license or a certificate, enter the
                                            number. You should enclose an
                                            invoice for all commercial items.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <input
                                name="commercialCertificateNumber"
                                id="commercialCertificateNumber"
                                className="form-control"
                                type="text"
                                value={this.state.commercialCertificateNumber}
                                onChange={this.handleCommercialSender}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="commercialInvoiceNumber">
                                  Invoice Number
                                </label>
                                <div className="more-info">
                                  <span
                                    className="more-info-link modal-trigger"
                                    data-toggle="modal"
                                    data-target="#modal-commercialInvoiceNumber"
                                  >
                                    <span>
                                      <InvoiceNumberPopover targetName="returnInvoiceNumberPopover" />
                                    </span>
                                    {/* <img
                                      className="tooltip-icon"
                                      src={info}
                                      alt="More info"
                                      width=" 18"
                                      height="18"
                                    /> */}
                                  </span>
                                  <div
                                    className="more-info-content modal fade"
                                    id="modal-commercialInvoiceNumber"
                                  >
                                    <div
                                      className="modal-dialog modal-lg"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <p>
                                            If your item is accompanied by a
                                            license or a certificate, enter the
                                            number. You should enclose an
                                            invoice for all commercial items.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <input
                                name="commercialInvoiceNumber"
                                id="commercialInvoiceNumber"
                                className="form-control"
                                type="text"
                                value={this.state.commercialInvoiceNumber}
                                onChange={this.handleCommercialSender}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.displayContinueButton() ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary force-show"
                                    id="submit"
                                    onClick={(e) =>
                                      this.handleContinueButton(e)
                                    }
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(CommercialSender);
